// eslint-disable-next-line no-unused-vars
import { useState, useEffect } from 'react';

import { cpf, cnpj } from 'cpf-cnpj-validator';
import {
    ADDRESS_KEY_TYPE_PHONE,
    ADDRESS_KEY_TYPE_CNPJ,
    ADDRESS_KEY_TYPE_CPF,
    ADDRESS_KEY_TYPE_EMAIL,
    ADDRESS_KEY_TYPE_EVP
} from 'constants/';

const pipe = (...fns) => (x) => fns.reduce((v, f) => f(v), x);

const phoneRegex = /[1-9]{2}9[1-9][0-9]{7}/;
const emailRegexs = [
    /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/i, 
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
];

const phoneValidator = (value) => phoneRegex.test(value);
const emailValidator = (value) => emailRegexs.every(regex => regex.test(value));

const fieldStyle = {
    [ADDRESS_KEY_TYPE_CPF.canonical]: {
        validator: cpf.isValid,
        mask: '999.999.999-99',
        spacing: null,
        substr: null,
        type: "tel",
        placeholder: '000.000.000-00',
        unmask: (value) => value.replace(/[\D]/g, '')
    },
    [ADDRESS_KEY_TYPE_PHONE.canonical]: {
        validator: phoneValidator,
        mask: '(99) 99999-9999',
        spacing: null,
        substr: null,
        placeholder: '(00) 00000-0000',
        type: "tel",
        unmask: (value) => {
            const hasCountryCode = value.startsWith('+55');
            if (hasCountryCode) {
                value = value.replace('+55', '');
            }
            value = value.replace(/[\D]/g, '');
            return `+55${value}`;
        }
    },
    [ADDRESS_KEY_TYPE_EMAIL.canonical]: {
        validator: emailValidator,
        mask: null,
        spacing: null,
        substr: null,
        type: "email",
        placeholder: 'nome@mail.com',
        unmask: (value) => value
    },
    [ADDRESS_KEY_TYPE_CNPJ.canonical]: {
        validator: cnpj.isValid,
        mask: '99.999.999/9999-99',
        spacing: null,
        substr: null,
        type: "tel",
        placeholder: '00.000.000/0000-00',
        unmask: (value) => value
    },
    [ADDRESS_KEY_TYPE_EVP.canonical]: {
        unmask: (value) => value
    }
};

const checkForType = (params) => {
    const {
        type: { canonical },
    } = params;

    const settings = canonical in fieldStyle ? fieldStyle[canonical] : {};

    return {...params, settings };
};

export const mountSelectedTypeSettings = (type) => {
    const maskedFieldSettings = () =>
        pipe(
            checkForType
        )({
            type
        });

    return maskedFieldSettings();
};

export const useDebounce = (value, timeout) => {
    const [state, setState] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => setState(value), timeout);

        return () => clearTimeout(handler);
    }, [value, timeout]);

    return state;
};