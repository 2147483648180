const queries = {
  instantPaymentVerification: (variables) => ({
    payload: {
      variables,
      query: `
        query($qrCodeId: String!) {
          instantPaymentVerification(qrCodeId: $qrCodeId) {
            qrCodeId
            qrCode
            status
            amount
            initiatorTxId
            additionalData
          }
        }
      `,
    },
  }),
  getPixAddressKeys: () => ({
    payload: {
      query: `
        query {
          addressKeys {
            creationDate
            currentStatus
            key
            keyOwnershipDate
            keyType
            ownerName
            ownerTaxIdNumber
            ownerType
          }
        }
      `,
    },
  }),
  listClaim: () => ({
    payload: {
      query: `
      query {
        claims {
          addressKey {
            key
            keyType
            ownerName
            ownerTaxIdNumber
            ownerType
            currentStatus
            active
          }
          claimType
          currentStatus
          participationType
          conclusionLimitDate
        }
     }
      `,
    },
  }),
  getViewer: () => ({
    payload: {
      query: `
        query Viewer {
          viewer {
            profile {
              fullName
              email
              nationalId
              nationalDocumentKind
            }
            merchantData {
              personalProfile {
                firstName,
                lastName,
              }
              merchantProfile {
                merchantCode,
              },
              onboardingBankData {
                eligible
              }
            }
          }
        }
      `,
    },
  }),
  getIOViewer: () => ({
    payload: {
      query: `
        query Viewer {
          viewer {
            profile {
              fullName
              email
              nationalId
              nationalDocumentKind
            }
          }
        }
      `,
    },
  }),
  getAddressSuggestionViewer: () => ({
    payload: {
      query: `
        query Viewer {
          viewer {
            merchantData {
              onboardingBankData {
                addressSuggestion {
                  street
                  number
                  complement
                  district
                  zipcode
                  city
                  state
                }
                eligible
              }
              personalProfile {
                firstName
                lastName
              }
              merchantProfile {
                merchantCode
              }
            }
          }
        }
      `,
    },
  }),
};

const mutations = {
  generateQR: (variables) => ({
    payload: {
      variables,
      query: `
      mutation InitiateInstantPayment($input: InitiateInstantPaymentInput!) {
        InitiateInstantPayment(input: $input) {
          successful
          result {
            qrCodeId
            qrCode
            status
            amount
            initiatorTxId
            additionalData
          }
          messages {
            code
            message
            template
          }
        }
      }
      `,
    },
  }),
  deleteAddressKey: (variables) => ({
    payload: {
      variables,
      query: `
      mutation deleteAddressKey($key: String!) {
        deleteAddressKey(input: { key: $key }) {
          successful
          result {
            key
          }
          messages {
            code
            message
            template
          }
        }
      }
      `,
    },
  }),
  createClaim: (variables) => ({
    payload: {
      variables,
      query: `
      mutation createClaim($input: CreateClaimInput!){
        createClaim(input: $input)  {
          successful
          messages {
            code
            message
            template
          }
        }
      }
      `,
    },
  }),
  acceptClaim: (variables) => ({
    payload: {
      variables,
      query: `
        mutation confirmClaim($input: ConfirmClaimInput!){
          confirmClaim(input: $input) {
            successful
            messages {
              code
              message
              template
            }
          }
        }
      `,
    },
  }),
  completeClaim: (variables) => ({
    payload: {
      variables,
      query: `
        mutation completeClaim($input: CompleteClaimInput!){
          completeClaim(input: $input) {
            successful
            messages {
              code
              message
              template
            }
          }
        }
      `,
    },
  }),
  rejectClaim: (variables) => ({
    payload: {
      variables,
      query: `
        mutation cancelClaim($input: CancelClaimInput!){
          cancelClaim(input: $input) {
            successful
            messages {
              code
              message
              template
            }
            result {
              addressKey {
               accountId
               active
              }
              conclusionLimitDate
              confirmationLimitDate
              currentStatus
              dictId
            }
          }
        }
      `,
    },
  }),
  validateToken: (variables) => ({
    payload: {
      variables,
      query: `
        mutation($input: ValidateTokenInput!) {
          validateToken(input: $input) {
            successful
            result {
              valid
            }
            messages {
              code
              message
              template
              options {
                accountParticipantName
              }
            }
          }
        }
      `,
    },
  }),
  createAddressKey: (variables) => ({
    payload: {
      variables,
      query: `
          mutation CreateAddressKey($input: CreateAddressKeyInput!) {
            createAddressKey(input: $input) {
            successful
            result {
              key
              creationDate
              keyOwnershipDate
            }
            messages {
              code
              message
              template
              options {
                accountParticipantName
              }
          }
        }
      }`,
    },
  }),
  createAccount: (variables) => ({
    payload: {
      variables,
      query: `
        mutation CreateAccount($input: CreateMerchantBankAccountInput!) {
          createMerchantBankAccount(input: $input) {
            successful
            result {
              viewer {
                fullName
                email
                nationalId
                nationalDocumentKind
                merchantData {
                  merchantProfile {
                    merchantCode,
                  }
                }
              }
            }
            messages {
              code
              message
              options {
                merchantCode
              }
              template
            }
          }
        }
      `,
    },
  }),
};

export const graphql = {
  queries,
  mutations,
};
