import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appSteps } from 'modules/appSteps';
import { appSetStep } from 'actions';
import { useHistory, useLocation } from 'react-router-dom';
import { QRCodeScreen, QRCodePaymentContainer } from 'containers/Checkout';
import { Button } from '@sumup/circuit-ui';
import { isExecutingInBankApp, executeNativeBack } from 'modules/dom';
import { formatValue } from 'modules/content';

const buttonStyle = {};

const QRCodePayment = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    state: { checkoutKey },
  } = useLocation();
  const { key, keyType } = checkoutKey;
  const { qrcode, price, description } = useSelector((state) => state.checkout);

  /** On The Merchant app future version we must to check the payment
  const { changedFrom } = useTreeChanges(payment.status);

  useEffect(() => {
    dispatch(checkoutQRPaymentCheck({ qrCodeId: qrcodeId }));
    setIsProcessing(true);
  }, [isProcessing, dispatch, qrcodeId]);

  useEffect(() => {
    if (changedFrom('payment.status', STATUS.RUNNING, STATUS.SUCCESS)) {
      setIsProcessing(false);
      setHasError(false);
      history.replace('/checkout/success');
    }
  }, [changedFrom, payment.status, history]);

  useEffect(() => {
    if (changedFrom('payment.status', STATUS.RUNNING, STATUS.FAILURE)) {
      setIsProcessing(false);
      setHasError(true);
    }
  }, [changedFrom, payment.status]);
  **/

  useEffect(() => {
    dispatch(
      appSetStep({
        currentStep: appSteps.qrcodePayment.step,
        beforeGoBack: () => executeNativeBack(),
      })
    );
  }, [dispatch]);

  const onNewPixClickHandler = () => history.replace('/checkout');
  const onCloseClickHandler = () =>  {
    if(isExecutingInBankApp) {
      executeNativeBack();
    } else {
      history.replace('/keys-management');
    }
  }

  return (
    <>
      <QRCodePaymentContainer>
        <QRCodeScreen
          qrcodeValue={qrcode || ''}
          paymentKey={formatValue(key, keyType?.canonical)}
          paymentPrice={price}
          qrcodeDescription={price 
            ? "Escaneie e pague pelo celular"
            : "Escaneie, digite o valor e pague pelo celular"}
          paymentDescription={description}
          waitforPayment={false}
        />
        <div className="button-container">
          <Button
            onClick={onNewPixClickHandler}
            stretch={true}
            variant="secondary"
            css={buttonStyle}
          >
            Receber novo Pix
          </Button>
          <Button
            onClick={onCloseClickHandler}
            stretch={true}
            variant="primary"
            css={buttonStyle}
          >
            Fechar
          </Button>
        </div>
      </QRCodePaymentContainer>
    </>
  );
};

export default QRCodePayment;
