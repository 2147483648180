import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import KeysSelectionList from 'containers/KeysSelectionList';
import { TitleStyled, Container, NoKeys } from 'containers/Checkout';
import { useHistory } from 'react-router-dom';
import { appSteps } from 'modules/appSteps';
import { useAppStep } from 'hooks';
import { checkoutKeyManagementFlowPush } from 'actions';

const KeySelection = () => {
  useAppStep(appSteps.keySelection.step);

  const dispatch = useDispatch();
  const history = useHistory();

  const { addressKeys } = useSelector((state) => state.addressKey);
  const createdKeys = (addressKeys.list || []).filter(a => a.currentStatus === "CREATED")
  const hasCreatedKeys = createdKeys.length > 0;
  
  const onSelectedKeyHandle = (key) => {
    history.push('/checkout/new', { checkoutKey: key });
  };
  const onClickHandler = () => {
    dispatch(checkoutKeyManagementFlowPush());
    history.push('/keys-management');
  };

  return <Container>
          <TitleStyled>
          Escolha uma <strong>chave Pix</strong> para receber o pagamento por QR Code
          </TitleStyled>
          {hasCreatedKeys 
            ? <KeysSelectionList keysToShow={createdKeys} onClick={onSelectedKeyHandle} />
            : <NoKeys onNewKeyClick={onClickHandler} />}
        </Container>;
};

export default KeySelection;
