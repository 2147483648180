import { handleActions } from 'redux-actions';
import produce from 'immer';
import { AccountConstants, STATUS } from 'constants';

export const accountState = {
  viewer: {
    message: '',
    status: STATUS.IDLE,
  },
  viewerAddress: {
    message: '',
    status: STATUS.IDLE,
  },
  create: {
    message: '',
    status: STATUS.IDLE,
  },
  data: {},
  profile: {
    fullName: null,
    email: null,
    merchantCode: null,
  },
  flags: {
    userHasAccount: null,
    userIsEligible: null,
  },
};

export default {
  account: handleActions({
    // Account create
    [AccountConstants.ACCOUNT_CREATE_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.create = {
          message: '',
          status: STATUS.RUNNING,
        };
        draft.flags = {};
      }),
    [AccountConstants.ACCOUNT_CREATE_FAILURE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.create = {
          message: payload.message,
          status: STATUS.ERROR,
        };
        draft.data = payload;
        draft.flags = {};
      }),
    [AccountConstants.ACCOUNT_CREATE_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.create = {
          message: '',
          status: STATUS.SUCCESS,
        };
        draft.profile = {
          ...payload,
          merchantCode: payload?.merchantData?.merchantProfile?.merchantCode,
        };

        draft.flags.userHasAccount = true;
      }),
    // Get viewer
    [AccountConstants.ACCOUNT_VIEWER_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.viewer = {
          message: '',
          status: STATUS.RUNNING,
        };
        draft.flags = {};
      }),
    [AccountConstants.ACCOUNT_VIEWER_FAILURE]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.data = payload;
        draft.viewer = {
          message: payload.message,
          status: STATUS.ERROR,
        };
        draft.flags = {};
      }),
    [AccountConstants.ACCOUNT_VIEWER_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.viewer = {
          message: '',
          status: STATUS.SUCCESS,
        };
        draft.data = payload;
        draft.profile = {
          fullName: payload?.profile?.fullName,
          email: payload?.profile?.email,
          merchantCode: payload?.merchantData?.merchantProfile?.merchantCode,
          nationalId: payload?.profile?.nationalId,
          nationalDocumentKind: payload?.profile?.nationalDocumentKind,
        };
        draft.flags.userHasAccount = !!draft.profile.fullName;
        draft.flags.userIsEligible = !!payload?.merchantData
            ?.onboardingBankData?.eligible;
      }),
    [AccountConstants.ACCOUNT_ADDRESS_SUGGESTION_REQUEST]: (state) =>
      produce(state, (draft) => {
        draft.viewerAddress = {
          message: '',
          status: STATUS.RUNNING,
        };
        draft.flags = {};
      }),
    [AccountConstants.ACCOUNT_ADDRESS_SUGGESTION_FAILURE]: (state,
      { payload }) =>
      produce(state, (draft) => {
        draft.viewerAddress = {
          message: payload.message,
          status: STATUS.ERROR,
        };
        draft.flags = {};
      }),
    [AccountConstants.ACCOUNT_ADDRESS_SUGGESTION_SUCCESS]: (state,
      { payload }) =>
      produce(state, (draft) => {
        draft.viewerAddress = {
          message: '',
          status: STATUS.SUCCESS,
        };
        draft.data = {
          ...payload,
        };
        draft.profile = {
          addressSuggestion:
              payload?.merchantData?.onboardingBankData?.addressSuggestion,
        };
        draft.flags = {};
      }),
  },
  accountState),
};
