import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as KeyIcon } from 'assets/icons/icon-pictogram-key.svg';
import { Text } from '@sumup/circuit-ui';
import { BottomBar } from 'containers/Layout';
import {
  InfoCard,
  InfoCardButtonStyle,
  Wrapper,
} from '../KeysManagement.styles';

const NoKeys = ({ onNewKeyClick }) => {
  const cardIcon = <KeyIcon fill="white" />;
  const cardTitle = 'Você ainda não possui chaves Pix cadastradas';
  return (
    <Wrapper data-testid="no-keys">
      <InfoCard icon={cardIcon} title={cardTitle} showCardBox={true}>
        <Text>
          Selecione &ldquo;Gerenciar minhas chaves&rdquo; a seguir e cadastre
          uma nova chave Pix para poder receber pagamentos e transferências com
          mais facilidade.
        </Text>
      </InfoCard>
      <BottomBar>
        <InfoCardButtonStyle
          stretch={true}
          variant="primary"
          onClick={onNewKeyClick}
        >
          Gerenciar minhas chaves
        </InfoCardButtonStyle>
      </BottomBar>
    </Wrapper>
  );
};

NoKeys.propTypes = {
  onNewKeyClick: PropTypes.func,
};

export default NoKeys;
